import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { Header } from '../../components/FlightDrawer/FlightDrawer';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import { DashboardState } from '../../models';
import { AircraftResource } from '../../models/aircraft';
import { changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { addAircraftNote, deleteAircraftNote, editAircraftNote, getAircraftNotes } from '../../models/notes/actions';
import { GetNotesPayload, Note, NotePayload } from '../../models/notes';

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 2px rgba(227, 232, 240, 0.5);
  border: solid 2px #fff;
  background-color: #fcfdff;
  padding: 20px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
`;

const StyledInput = styled.input`
  width: 110px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const ButtonFlexWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
`;

const ButtonWrapper = styled.div`
  margin-right: 16px;
`;

const Text = styled.div`
  letter-spacing: 0.52px;
  color: rgba(36, 45, 65, 0.9);
`;

const ReportedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const FlexDiv = styled.div`
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
`;

const ModalWrapper = styled.div`
  height: auto;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  color: #242d41;
  padding-bottom: 12px;
`;

const ModalMessage = styled.div`
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 28px;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 16px;
`;

const NotesDrawer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [reportedBy, setReportedBy] = useState(null);
  const [reportedOn, setReportedOn] = useState(null);
  const [user, setUser] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const {
    drawer: { mode, drawerId },
    aircraft: { aircraftMap },
    userSettings: {
      details: { people },
      dateFormat,
    },
    notes: { notesMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const operatorId = aircraftMap.get(id)?.operator_id;

  const handleSaveClick = async (): Promise<void> => {
    const payload = {
      title,
      content,
      aircraft_id: id,
    } as NotePayload;
    let promiseResponse;
    if (drawerId) {
      payload.id = drawerId;
      promiseResponse = dispatch(editAircraftNote({ payload }));
    } else {
      payload.created_at = moment().toISOString();
      payload.person_id = user?.id;
      promiseResponse = dispatch(addAircraftNote({ payload }));
    }
    promiseResponse.then((response: Note) => {
      const newId = response?.id;
      dispatch(setDrawerId({ payload: newId }));
      const getPayload = {
        aircraft_id: id,
        page: 1,
        limit: 10,
        sort_by: [],
      } as GetNotesPayload;
      dispatch(getAircraftNotes({ payload: getPayload }));
    });
    dispatch(changeDrawerMode({ payload: 'view' }));
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') dispatch(changeDrawerVisibility({ payload: false }));
    if (mode === 'edit') dispatch(changeDrawerMode({ payload: 'view' }));
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleDeleteClick = (): void => {
    dispatch(deleteAircraftNote({ id: drawerId }));
    const getPayload = {
      aircraft_id: id,
      page: 1,
      limit: 10,
      sort_by: [],
    };
    dispatch(changeDrawerVisibility({ payload: false }));
    dispatch(getAircraftNotes({ payload: getPayload }));
  };

  useEffect(() => {
    if (operatorId && people) {
      const loggedInUser = people.find((person) => person?.organisation?.id === operatorId);
      setUser(loggedInUser);
    }
  }, [operatorId, people]);

  useEffect(() => {
    if (drawerId) {
      const noteData = notesMap.get(drawerId);
      if (noteData) {
        setTitle(noteData?.title);
        setContent(noteData?.content);
        setReportedBy(noteData?.created_by_name);
        setReportedOn(moment(noteData?.created_at).format(`${dateFormat} HH:mm`));
      }
      setLoading(false);
    }
  }, [dateFormat, drawerId, notesMap]);

  return (
    <DrawerWrapper>
      {loading ? (
        <Loading loading />
      ) : (
        <div>
          <Title>
            Notes
            {mode === 'view' ? (
              <AuthDropdownMenu
                options={{
                  read: false,
                  update: true,
                  delete: true,
                }}
                menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
                resource={AircraftResource.AIRCRAFT}
                aircraftId={id}
                editCallback={(): void => handleEditClick()}
                handleDelete={(): void => setDeleteModalVisible(true)}
              />
            ) : null}
          </Title>
          <DetailsWrapper>
            <TitleWrapper>
              <Header editable={mode !== 'view'}>Title</Header>
              {mode === 'view' ? (
                <Text>{title}</Text>
              ) : (
                <StyledInput
                  id="notes"
                  name="title"
                  data-testid="Notes--TextArea"
                  defaultValue={title}
                  onChange={(e): void => {
                    setTitle(e.target.value);
                  }}
                />
              )}
            </TitleWrapper>
            <div>
              <Header editable={mode !== 'view'}>Content</Header>
              {mode === 'view' ? (
                <Text>{content}</Text>
              ) : (
                <StyledTextarea
                  id="notes"
                  name="content"
                  data-testid="Notes--TextArea"
                  cols={30}
                  rows={5}
                  defaultValue={content}
                  onChange={(e): void => {
                    setContent(e.target.value);
                  }}
                />
              )}
            </div>
            {mode === 'view' ? (
              <ReportedWrapper>
                <FlexDiv marginRight={20}>
                  <Header>Reported by</Header>
                  <Text>{reportedBy}</Text>
                </FlexDiv>
                <FlexDiv>
                  <Header>Reported on</Header>
                  <Text>{reportedOn}</Text>
                </FlexDiv>
              </ReportedWrapper>
            ) : null}
            {mode !== 'view' ? (
              <ButtonFlexWrapper>
                <ButtonWrapper>
                  <Button disabled={loading} size={ButtonSize.MEDIUM} padding="0 28px" onClick={handleSaveClick}>
                    Save
                  </Button>
                </ButtonWrapper>
                <Button size={ButtonSize.MEDIUM} padding="0 28px" onClick={handleCancelClick} primary={false}>
                  Cancel
                </Button>
              </ButtonFlexWrapper>
            ) : null}
          </DetailsWrapper>
        </div>
      )}
      <Modal isOpen={deleteModalVisible} width={420} handleClose={(): void => setDeleteModalVisible(false)}>
        <ModalWrapper>
          <ModalTitle>{formatMessage({ id: 'title.deleteItem' })}</ModalTitle>
          <ModalMessage>
            {`${formatMessage({
              id: 'form.question.areYouSureDeleteNote',
            })} ${formatMessage({
              id: 'form.labels.cannotBeUndone',
            })}`}
          </ModalMessage>
          <ModalButtonWrapper>
            <SubmitButtonWrapper>
              <Button padding="0 28px" size={ButtonSize.MEDIUM} onClick={(): void => handleDeleteClick()}>
                Delete
              </Button>
            </SubmitButtonWrapper>
            <Button
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setDeleteModalVisible(false)}
            >
              Cancel
            </Button>
          </ModalButtonWrapper>
        </ModalWrapper>
      </Modal>
    </DrawerWrapper>
  );
};

export default NotesDrawer;
