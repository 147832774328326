import { Dispatch } from 'redux';
import { addNote, deleteNote, editNote, getNotes } from '../../services/api';
import { GetNotesPayload, Note, NotePayload, NotesActionTypes } from '.';

export const getAircraftNotes =
  ({ payload }: { payload: GetNotesPayload }) =>
  async (dispatch: Dispatch): Promise<void> => {
    const response = await getNotes(payload);
    dispatch({
      type: NotesActionTypes.SAVEALL,
      payload: response,
    });
  };

export const addAircraftNote =
  ({ payload }: { payload: NotePayload }) =>
  async (dispatch: Dispatch): Promise<Note> => {
    const response = await addNote(payload);
    dispatch({
      type: NotesActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const editAircraftNote =
  ({ payload }: { payload: NotePayload }) =>
  async (dispatch: Dispatch): Promise<Note> => {
    const response = await editNote(payload);
    dispatch({
      type: NotesActionTypes.SAVE,
      payload: response,
    });
    return response;
  };

export const deleteAircraftNote =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    await deleteNote(id);
    dispatch({
      type: NotesActionTypes.DELETE,
      id,
    });
  };
