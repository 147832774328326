import { Person } from '../people';

const initialState: NotesState = {
  notesMap: new Map(),
  count: 0,
};

export enum NotesActionTypes {
  SAVE = 'notes/save',
  SAVEALL = 'notes/saveAll',
  UPDATE = 'notes/update',
  DELETE = 'notes/delete',
}

interface SaveNotesActionType {
  type: NotesActionTypes.SAVE;
  payload: Note;
}

interface SaveAllNotesActionType {
  type: NotesActionTypes.SAVEALL;
  payload: {
    notes: Note[];
    count: number;
  };
}

interface UpdateNotesActionType {
  type: NotesActionTypes.UPDATE;
  payload: Note;
}

interface DeleteNotesActionType {
  type: NotesActionTypes.DELETE;
  id: string;
}

type NotesAction = SaveNotesActionType | SaveAllNotesActionType | UpdateNotesActionType | DeleteNotesActionType;

export default function notesReducer(state = initialState, action: NotesAction): NotesState {
  switch (action.type) {
    case NotesActionTypes.SAVEALL: {
      const notesMap = new Map();
      if (action?.payload?.notes && Array.isArray(action?.payload?.notes)) {
        action.payload.notes.forEach((note) => {
          notesMap.set(note?.id, {
            ...note,
          });
        });
      }
      return {
        ...state,
        notesMap,
        count: action?.payload?.count || 0,
      };
    }
    case NotesActionTypes.SAVE: {
      const notesMap = new Map(state.notesMap.entries());
      if (action?.payload?.id) {
        notesMap.set(action.payload.id, {
          ...action.payload,
        });
      }
      return {
        ...state,
        notesMap,
      };
    }
    case NotesActionTypes.UPDATE: {
      const notesMap = new Map(state.notesMap.entries());
      if (action?.payload?.id) {
        notesMap.set(action.payload.id, {
          ...action.payload,
        });
      }
      return {
        ...state,
        notesMap,
      };
    }
    case NotesActionTypes.DELETE: {
      const notesMap = new Map(state.notesMap.entries());
      if (action?.id) {
        notesMap.delete(action.id);
      }
      return {
        ...state,
        notesMap,
      };
    }
    default:
      return state;
  }
}

export interface NotesState {
  notesMap: Map<string, Note>;
  count: number;
}

export interface Note {
  id: string;
  last_updated: string;
  created_at: string;
  is_deleted: boolean;
  title: string;
  content: string;
  type: string;
  created_by: Person;
  created_by_name: string;
}

export interface NotePayload {
  title: string;
  content: string;
  aircraft_id: string;
  id: string;
  created_at: string;
  person_id: string;
}

export interface GetNotesPayload {
  aircraft_id: string;
  page: number;
  limit: number;
  sort_by: { [key: string]: 'asc' | 'desc' }[];
  search?: string;
}
